import { useState, useEffect } from "react";
import MediaQuery from "react-responsive";

const Burger = ({ className, onClick, visibleRightSidebar }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? (
    <MediaQuery maxWidth={760}>
      <button
        className={`z-[25] shrink-0 flex flex-col items-center justify-center w-8 h-8 tap-highlight-color absolute top-2 right-5 m-0 ${className}`}
        onClick={onClick}
      >
        <span
          className={`w-5 h-0.5 my-0.5  rounded-full transition-all bg-n-4 ${
            visibleRightSidebar && "translate-y-0.75 rotate-45"
          }`}
        ></span>
        <span
          className={`w-5 h-0.5 my-0.5  rounded-full transition-all bg-n-4 ${
            visibleRightSidebar && "-translate-y-0.75 -rotate-45"
          }`}
        ></span>
      </button>
    </MediaQuery>
  ) : null;
};

export default Burger;

export const ROUTES = {
  ROOT: "/",
  AUTH: "/sign-in",
  SIGN_IN: "/sign-in",
  REGISTER: "/register",
  RESET_PASSWORD: "/reset-password",
  DASHBOARD: "/dashboard",
  SETTINGS: "/settings",
  EDIT_PROFILE: "/edit-profile",
  CHANGE_PASSWORD: "/change-password",
  EMAIL_VERIFICATION: "/email-verification",
  VIDEO_MAKER: "/video-maker",
  LIBRARY: "/library",
  VIDEO_GALLERY: "video-gallery",
  EDIT_VIDEO: "edit-video",
  GALLERY: "/gallery",
  FABRIC_CANVAS: "/fabric-canvas",
  IMAGE_EDITOR: "/image-editor",
};

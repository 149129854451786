import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import TextareaAutosize from "react-textarea-autosize";

import Icon from "../Icon";

const Field = forwardRef((props, ref) => {
  const {
    className,
    classInput,
    label,
    textarea,
    note,
    type,
    icon,
    error = "",
    minRows = 3,
    ...otherProps
  } = props;

  return (
    <div className={`${className}`}>
      {label && <div className="flex mb-2 font-semibold base2">{label}</div>}
      <div className="relative">
        {textarea ? (
          <TextareaAutosize
            className={twMerge(
              "w-full h-24 px-3.5 py-3 border-2 rounded-xl base2 outline-none transition-colors placeholder:text-n-4/50 resize-none bg-n-6 border-n-6 text-n-3 focus:bg-transparent",
              icon && "pl-[3.125rem]",
              classInput
            )}
            minRows={minRows}
            ref={ref}
            {...otherProps}
          />
        ) : (
          <input
            className={twMerge(
              "w-full h-13 px-3.5  border-2  rounded-xl base2 outline-none transition-colors placeholder:text-n-3/50  bg-n-6 border-n-6 text-n-3 focus:bg-transparent",
              icon && "pl-[3.125rem]",
              classInput
            )}
            ref={ref}
            type={type || "text"}
            {...otherProps}
          />
        )}
        <Icon
          className={`absolute top-3.5 left-4 fill-n-4/50 pointer-events-none transition-colors $`}
          name={icon}
        />
      </div>
      {note && !error && <div className="mt-2 base2 text-n-3/50">{note}</div>}
      {error && <div className="mt-2 caption1 text-accent-1">{error}</div>}
    </div>
  );
});

export default Field;

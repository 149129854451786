import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import WaveSurfer from "wavesurfer.js";

import Icon from "components/Icon";

const AudioPlayer = ({ audioFile, name, isSelected, autoPlay = false }) => {
  const waveformRef = useRef(null);
  const waveSurfer = useRef(null);

  const [isPlaying, setIsPlaying] = useState(autoPlay);

  const formWaveSurferOptions = (ref) => ({
    container: ref,
    waveColor: isSelected ? "#6C7275" : "#F3F3F3",
    progressColor: "#0084FF",
    cursorColor: "transparent",
    barWidth: 3,
    barRadius: 3,
    responsive: true,
    height: 20,
    hideScrollbar: true,
    xhr: {
      mode: "cors",
    },
    // normalize: true,
  });

  // Initialize wavesurfer and set up event listeners
  useEffect(() => {
    // Create WaveSurfer instance with options
    const option = formWaveSurferOptions(waveformRef.current);
    waveSurfer.current = WaveSurfer.create(option);

    // Load audio file
    waveSurfer.current.load(audioFile);

    // When WaveSurfer is ready
    waveSurfer.current.on("ready", function () {
      // Play audio
      if (autoPlay) {
        waveSurfer.current.play();
      }
    });

    // When audio finished playing
    waveSurfer.current.on("finish", function () {
      setIsPlaying(false);
    });

    // error handling
    waveSurfer.current.on("error", function (e) {
      console.error(e);
    });

    // Clean up event listeners and destroy WaveSurfer instance on unmount
    return () => {
      waveSurfer.current.un("error");
      waveSurfer.current.un("ready");
      waveSurfer.current.un("finish");
      waveSurfer.current.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioFile, autoPlay]);

  const handlePlayPause = () => {
    // check if the audio is playing
    if (waveSurfer.current.isPlaying()) {
      // pause the audio
      waveSurfer.current.pause();
      setIsPlaying(false);
    } else {
      // play the audio
      waveSurfer.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div
      className={twMerge(
        "w-full h-16 px-3.5 border-2 rounded-xl base2 outline-none transition-colors placeholder:text-n-4/50 bg-n-6 focus:bg-transparent flex items-center justify-between border-n-5 text-n-1 hover:bg-n-3/50 group gap-4 overflow-hidden hover:text-black",
        isSelected && "bg-n-2 text-black"
      )}
    >
      <div className="flex items-center gap-[2px] md:gap-4">
        <button type="button" onClick={handlePlayPause} className="">
          <Icon
            className={twMerge(
              "md:w-8 md:h-8 w-7 h-7 p-2 mr-3 transition-colors rounded-full fill-n-7 bg-n-1 group-hover:bg-primary-2 group-hover:fill-n-1",
              isSelected && "fill-n-1 bg-primary-2"
            )}
            name={isPlaying ? "pause" : "play"}
          />
        </button>
        {name && <p>{name}</p>}
      </div>
      <div ref={waveformRef} className={twMerge(name ? "w-[70%]" : "w-full")} />
    </div>
  );
};

export default AudioPlayer;

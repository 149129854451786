// import toast from "react-hot-toast";

import MyRoutes from "routes/Router";
// import useNotification from "hooks/useNotification";
// import { onMessageListener } from "firebase/firebaseConfig";

function App() {
  // Hook to request notification permission and get the FCM token
  // useNotification();

  // // Listen to the incoming message and show a toast notification
  // onMessageListener().then((payload) => {
  //   toast(
  //     <div>
  //       <h1 className="text-base font-semibold text-center text-gray-800 capitalize">
  //         {payload.notification.title}
  //       </h1>
  //       <p className="text-sm text-gray-700">{payload.notification.body}</p>
  //     </div>,
  //     { duration: 10000, style: { padding: "1rem" } }
  //   );
  // });

  return <MyRoutes />;
}

export default App;

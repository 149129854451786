import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import toast from "react-hot-toast";
import { Mutex } from "async-mutex";

import { loggedOut } from "store/AuthSlice";
import { API_BASE_URL } from "utils/api";

const mutex = new Mutex();

export const baseQueryWithReAuth =
  (baseUrl = API_BASE_URL) =>
  async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({ baseUrl });
    const newArgs = {
      ...args,
      headers: {
        ...args.headers,
        Authorization: `Token ${localStorage.getItem("token")}`,
        "ngrok-skip-browser-warning": true,
      },
    };

    await mutex.waitForUnlock();
    let result = await baseQuery(newArgs, api, extraOptions);

    if (result.error) {
      console.log("result.error", result.error);
      if (result.error.status === 401 || result.error.originalStatus === 401) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          try {
            api.dispatch(loggedOut());
          } finally {
            release();
          }
        }
        toast.error("You have been logged out. Please login again.");
      }
    }
    await mutex.waitForUnlock();
    return result;
  };

import { Listbox, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import Icon from "components/Icon";
import { nanoid } from "@reduxjs/toolkit";

import React, { useState } from "react";

const Select = ({
  id,
  label,
  title,
  icon,
  className,
  classButton,
  classArrow,
  classOptions,
  classOption,
  classIcon,
  placeholder,
  items,
  value,
  onChange,
  small,
  up,
  valueField = "title",
  error = "",
  disabled = false,
  handleEventPropagation = () => {},
}) => {
  const [otherValue, setOtherValue] = useState("");

  const handleInputChange = (e) => {
    e.stopPropagation();

    setOtherValue(e.target.value);
    onChange({ id: null, value: e.target.value, title: e.target.value });
  };

  const handleOptionClick = (item) => {
    if (item.title === "Other") {
      setOtherValue("");
    } else {
      onChange(item);
    }
  };

  const handleInputClick = (e) => {
    // Prevent the click event from bubbling up to the dropdown options
    e.stopPropagation();
  };

  return (
    <div
      id={id}
      className={`relative capitalize ${className}`}
      onClick={handleEventPropagation}
    >
      {label && <div className="flex mb-2 font-semibold base2">{label}</div>}
      <Listbox value={value} disabled={disabled}>
        {({ open }) => (
          <>
            <Listbox.Button
              className={twMerge(
                `flex items-center w-full min-w-30 h-[3.25rem] px-4 rounded-lg bg-n-1 base2 outline-none tap-highlight-color 
               disabled:cursor-not-allowed
               ${
                 small
                   ? `h-9 pr-3 rounded-md  shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.15),inset_0_0_0_0.0625rem_rgba(254,254,254,.1)] bg-n-6 ${
                       open && "shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.15)]"
                     }`
                   : ` shadow-[inset_0_0_0_0.0625rem_#343839] bg-transparent ${
                       open && "!shadow-[inset_0_0_0_0.125rem_#0084FF]"
                     }`
               }`,
                classButton
              )}
            >
              {title && (
                <div className="pr-2 mr-2 border-r shrink-0 text-n-4 border-n-4/50">
                  {title}
                </div>
              )}
              {icon && (
                <Icon
                  className={`shrink-0 mr-2 fill-n-4 ${
                    small && "w-5 h-5 mr-1.5"
                  } ${classIcon}`}
                  name={icon}
                />
              )}
              {value?.color && (
                <div
                  className="shrink-0 w-3.5 h-3.5 ml-1 mr-4 rounded"
                  style={{ backgroundColor: value.color }}
                ></div>
              )}
              {value?.icon && (
                <Icon className="w-5 h-5 mr-3 fill-n-1" name={value.icon} />
              )}
              <span
                className={`mr-auto truncate capitalize ${
                  small && "font-semibold"
                }`}
              >
                {value ? (
                  value.title
                ) : (
                  <span className="text-n-4">{placeholder}</span>
                )}
              </span>
              {!disabled && (
                <Icon
                  className={`shrink-0 ml-2 transition-transform fill-n-1 ${
                    open && "rotate-180"
                  } ${small && "ml-1"} ${classArrow}`}
                  name="arrow-down"
                />
              )}
            </Listbox.Button>
            <Transition
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={twMerge(
                  `absolute left-0 right-0 w-full mt-2 p-2 rounded-lg
                  shadow-[0_0_1rem_0.25rem_rgba(0,0,0,0.04),0_2rem_2rem_-1.5rem_rgba(0,0,0,0.1),inset_0_0_0_0.0625rem_#343839] bg-n-6
                  outline-none max-h-64 overflow-auto scrollbar-thin z-10 ${
                    small && "right-auto mt-1 shadow-md"
                  } ${
                    up && `top-auto bottom-full mt-0 ${small ? "mb-1" : "mb-2"}`
                  }`,
                  classOptions
                )}
              >
                {Array.isArray(items) &&
                  items?.map((item, index) =>
                    item?.title !== "Other" ? (
                      <Listbox.Option
                        value={item}
                        key={item?.id || index}
                        className={twMerge(
                          "flex items-start p-2 rounded-lg base2 text-n-4 transition-colors cursor-pointer ui-selected:!bg-n-3/50  tap-highlight-color hover:text-n-3 ui-selected:!bg-n-7 ui-selected:!text-n-1",
                          small && "py-1 font-semibold",
                          classOption
                        )}
                        onClick={() => handleOptionClick(item)}
                      >
                        {item?.color && (
                          <div
                            className="shrink-0 w-3.5 h-3.5 mt-[0.3125rem] ml-1 mr-4 rounded"
                            style={{ backgroundColor: item?.color }}
                          />
                        )}
                        {item?.icon && (
                          <Icon
                            className="w-5 h-5 mt-0.5 mr-3 fill-n-1"
                            name={item?.icon}
                          />
                        )}
                        <div className="mr-auto">{item?.[valueField]}</div>
                        {!small && (
                          <Icon
                            // className="hidden w-5 h-5 ml-2 mt-0.5 ui-selected:inline-block fill-n-1"
                            className={twMerge(
                              "hidden w-5 h-5 ml-2 mt-0.5 fill-n-1",
                              item?.id === value?.id && "inline-block"
                            )}
                            name="check-thin"
                          />
                        )}
                      </Listbox.Option>
                    ) : (
                      <Listbox.Option
                        value={{
                          id: nanoid(),
                          value: otherValue,
                          title: otherValue,
                        }}
                        key="other"
                        className={twMerge(
                          "flex items-start p-2 rounded-lg base2 text-n-4 transition-colors cursor-pointer ui-selected:!bg-n-3/50  tap-highlight-color hover:text-n-3 ui-selected:!bg-n-7 ui-selected:!text-n-1",
                          small && "py-1 font-semibold",
                          classOption
                        )}
                      >
                        <input
                          type="text"
                          value={otherValue}
                          onChange={handleInputChange}
                          onClick={handleInputClick} // Add onClick handler
                          placeholder="Other..."
                          className="flex-1 bg-transparent outline-none text-n-4"
                        />
                      </Listbox.Option>
                    )
                  )}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
      {error && <div className="mt-2 ml-1 caption1 text-accent-1">{error}</div>}
    </div>
  );
};

export default Select;

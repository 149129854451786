import { createApi } from "@reduxjs/toolkit/query/react";

import { EDIT_VIDEO_END_POINTS } from "api/endPoints";
import { baseQueryWithReAuth } from "utils/baseQueryWithReauth";
import { HTTP_REQUEST_METHODS } from "utils/constants";

export const editVideoApi = createApi({
  reducerPath: "editVideoApi",
  baseQuery: baseQueryWithReAuth(),
  endpoints: (builder) => ({
    getEditVideoDataById: builder.mutation({
      query: ({ videoId }) => ({
        url: `${EDIT_VIDEO_END_POINTS.GET_EDIT_VIDEO_DATA}?video_id=${videoId}`,
        method: HTTP_REQUEST_METHODS.GET,
      }),
    }),

    trimVideo: builder.mutation({
      query: (payload) => ({
        url: EDIT_VIDEO_END_POINTS.TRIM_VIDEO,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    addLogo: builder.mutation({
      query: (payload) => ({
        url: EDIT_VIDEO_END_POINTS.ADD_LOGO,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    editVideo: builder.mutation({
      query: (payload) => ({
        url: EDIT_VIDEO_END_POINTS.EDIT_VIDEO,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    generateIntroImage: builder.mutation({
      query: (payload) => ({
        url: EDIT_VIDEO_END_POINTS.GENERATE_INTRO_IMAGE,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    generateOutroImage: builder.mutation({
      query: (payload) => ({
        url: EDIT_VIDEO_END_POINTS.GENERATE_OUTRO_IMAGE,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    updateEditVideoData: builder.mutation({
      query: (payload) => ({
        url: EDIT_VIDEO_END_POINTS.EDIT_VIDEO,
        method: HTTP_REQUEST_METHODS.PATCH,
        body: payload,
      }),
    }),
  }),
});

export const {
  useTrimVideoMutation,
  useAddLogoMutation,
  useEditVideoMutation,
  useGenerateIntroImageMutation,
  useGenerateOutroImageMutation,
  useGetEditVideoDataByIdMutation,
  useUpdateEditVideoDataMutation,
} = editVideoApi;

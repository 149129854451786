import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import LogoImg from "assets/svg/logo.svg";

const Logo = ({ className, dark }) => (
  <Link className={twMerge("flex w-full", className)} to="/">
    <img className="w-full h-auto" src={LogoImg} alt="logo" />
  </Link>
);

export default Logo;

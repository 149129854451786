import Icon from "components/Icon";
import { twMerge } from "tailwind-merge";

const Menu = ({ buttons, value, setValue }) => {
  const handleClick = (value) => setValue(value);

  return buttons.map((button, index) => (
    <div className="mb-1" key={index}>
      {button.id === "logout" && <div className="h-0.25 my-3 bg-n-6"></div>}
      <button
        className={twMerge(
          "group flex items-center w-full px-3.5 py-1.5 rounded-full border-2 border-transparent base2 font-semibold transition-colors  tap-highlight-color hover:bg-n-6 hover:text-n-1 text-n-4",
          button.id === "logout" && `!text-accent-1`,
          value === button &&
            `!border-primary-1 !bg-n-1 !bg-transparent text-n-1`
        )}
        onClick={() => handleClick(button)}
      >
        <Icon
          className={twMerge(
            "w-4 h-4 mr-3 transition-colors fill-n-4 group-hover:fill-n-1",
            button.id === "logout" && `!fill-accent-1`,
            value === button && `fill-n-1 `
          )}
          viewBox={button.viewBox}
          name={button.icon}
        />
        {button.title}
      </button>
    </div>
  ));
};

export default Menu;

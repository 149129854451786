import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, compose } from "redux";
import { persistReducer } from "redux-persist";
import { thunk } from "redux-thunk";
import storage from "redux-persist/lib/storage";

import AuthSlice from "./AuthSlice";
import UserSlice from "./UserSlice";
import MiscellaneousSlice from "./MiscellaneousSlice";
import VideoMakerSlice from "./VideoMakerSlice";
import LibrarySlice from "./LibrarySlice";
import { videoMakerAPi } from "api/VideoMakerApi";
import { userApi } from "api/UserApi";
import { editVideoApi } from "api/EditVideoApi";
import { galleryApi } from "api/GalleryApi";
import EditVideoSlice from "store/EditVideoSlice";
import { videoLibraryApi } from "api/VideoLibraryApi";
import { miscApi } from "api/MiscApi";

const reducers = combineReducers({
  Auth: AuthSlice,
  User: UserSlice,
  Misc: MiscellaneousSlice,
  VideoMaker: VideoMakerSlice,
  Library: LibrarySlice,
  EditVideo: EditVideoSlice,
  [userApi.reducerPath]: userApi.reducer,
  [videoMakerAPi.reducerPath]: videoMakerAPi.reducer,
  [editVideoApi.reducerPath]: editVideoApi.reducer,
  [galleryApi.reducerPath]: galleryApi.reducer,
  [videoLibraryApi.reducerPath]: videoLibraryApi.reducer,
  [miscApi.reducerPath]: miscApi.reducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  version: 1,
  whitelist: ["Auth"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [
  thunk,
  videoMakerAPi.middleware,
  userApi.middleware,
  galleryApi.middleware,
  editVideoApi.middleware,
  videoLibraryApi.middleware,
  miscApi.middleware,
];

// const loggingMiddleware = (store) => (next) => (action) => {
//   console.log("Dispatching:", action);
//   let result = next(action);
//   console.log("Next State:", store.getState());
//   return result;
// };

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
  devTools: process.env.NODE_ENV !== "production",
  composeEnhancers,
});

export default store;

import { createApi } from "@reduxjs/toolkit/query/react";

import { END_POINTS } from "api/endPoints";
import { baseQueryWithReAuth } from "utils/baseQueryWithReauth";
import { HTTP_REQUEST_METHODS } from "utils/constants";

export const videoMakerAPi = createApi({
  reducerPath: "videoMakerAPi",
  baseQuery: baseQueryWithReAuth(),

  endpoints: (builder) => ({
    generateScript: builder.mutation({
      query: (payload) => ({
        url: END_POINTS.GENERATE_SCRIPT,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    getScriptDataById: builder.mutation({
      query: ({ id }) => ({
        url: `${END_POINTS.GENERATE_SCRIPT}?concept_id=${id}`,
        method: HTTP_REQUEST_METHODS.GET,
      }),
    }),
    generateVideo: builder.mutation({
      query: (payload) => ({
        url: END_POINTS.GENERATE_VIDEO,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    addScriptPart: builder.mutation({
      query: (payload) => ({
        url: END_POINTS.ADD_SCRIPT_PART,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    updateScriptOrder: builder.mutation({
      query: (payload) => ({
        url: END_POINTS.UPDATE_SCRIPT_PARTS_ORDER_NO,
        method: HTTP_REQUEST_METHODS.PATCH,
        body: payload,
      }),
    }),
    deleteScriptPart: builder.mutation({
      query: ({ id }) => ({
        url: END_POINTS.DELETE_SCRIPT_PART,
        method: HTTP_REQUEST_METHODS.DELETE,
        body: { id },
      }),
    }),
    addSubTitle: builder.mutation({
      query: (payload) => ({
        url: END_POINTS.ADD_SUBTITLE,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    changeVoice: builder.mutation({
      query: (payload) => ({
        url: END_POINTS.CHANGE_VOICE,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    generatePureRead: builder.mutation({
      query: (payload) => ({
        url: END_POINTS.GENERATE_PURE_READ,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    deletePureRead: builder.mutation({
      query: ({ conceptId }) => ({
        url: END_POINTS.GENERATE_PURE_READ,
        method: HTTP_REQUEST_METHODS.DELETE,
        body: { concept_id: conceptId },
      }),
    }),
    savePureReadAndGenerateVideo: builder.mutation({
      query: ({ conceptId }) => ({
        url: END_POINTS.GENERATE_PURE_READ,
        method: HTTP_REQUEST_METHODS.PATCH,
        body: { concept_id: conceptId },
      }),
    }),
    updateScriptPreRenderOptions: builder.mutation({
      query: (payload) => ({
        url: END_POINTS.UPDATE_SCRIPT_PRE_RENDER_OPTIONS,
        method: HTTP_REQUEST_METHODS.PATCH,
        body: payload,
      }),
    }),
  }),
});

export const {
  useAddScriptPartMutation,
  useUpdateScriptOrderMutation,
  useDeleteScriptPartMutation,
  useGenerateScriptMutation,
  useGetScriptDataByIdMutation,
  useAddSubTitleMutation,
  useChangeVoiceMutation,
  useGenerateVideoMutation,
  useGeneratePureReadMutation,
  useDeletePureReadMutation,
  useSavePureReadAndGenerateVideoMutation,
  useUpdateScriptPreRenderOptionsMutation,
} = videoMakerAPi;

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Field from "components/Field";
import Button from "components/Button";
import { ChangePasswordAction } from "store/AuthSlice";

const DEFAULT_VALUES = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const FORM_SCHEMA = object().shape({
  oldPassword: string().required("Old password is required"),
  newPassword: string().required("New password is required"),
  confirmPassword: string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.newPassword === value;
    })
    .required("Required"),
});

const Password = () => {
  const [buttonLoader, setButtonLoader] = useState(false);

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(FORM_SCHEMA),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const onSubmit = (data) => {
    const payload = {
      oldpassword: data.oldPassword,
      password: data.newPassword,
    };
    dispatch(
      ChangePasswordAction({
        payload,
        setButtonLoader,
        reset: form.reset,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-8 h4 md:mb-6">Password</div>
      <Field
        className="mb-6"
        label="Password"
        placeholder="Old Password"
        type="password"
        icon="lock"
        required
        autoComplete="off"
        error={errors.oldPassword?.message}
        {...register("oldPassword")}
      />
      <Field
        className="mb-6"
        label="New password"
        placeholder="New password"
        note="Minimum 8 characters"
        type="password"
        icon="lock"
        required
        autoComplete="off"
        error={errors.newPassword?.message}
        {...register("newPassword")}
      />
      <Field
        className="mb-6"
        label="Confirm new password"
        placeholder="Confirm new password"
        note="Minimum 8 characters"
        type="password"
        icon="lock"
        required
        autoComplete="off"
        error={errors.confirmPassword?.message}
        {...register("confirmPassword")}
      />

      <Button
        type="submit"
        title="Change password"
        loader={buttonLoader}
        className="w-full btn-blue"
      />
    </form>
  );
};

export default Password;

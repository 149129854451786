import { twMerge } from "tailwind-merge";

const Radio = ({
  className,
  content,
  value,
  name,
  onChange,
  disabled,
  ...otherProps
}) => (
  <label
    className={twMerge(
      "group relative block select-none cursor-pointer tap-highlight-color",
      disabled && "opacity-50 cursor-not-allowed",
      className
    )}
  >
    <input
      name={name}
      type="checkbox"
      value={value}
      checked={value}
      onChange={onChange}
      disabled={disabled}
      className="absolute top-0 left-0 invisible opacity-0 peer "
      {...otherProps}
    />
    <span
      className={twMerge(
        "flex items-center font-semibold transition-colors base2 text-n-4 before:shrink-0 before:w-5 before:h-5 before:mr-3 before:rounded-full before:border-2 before:border-n-4/50 before:transition-all group-hover:before:border-primary-2 peer-checked:text-n-7 peer-checked:before:border-6 peer-checked:before:border-primary-2",
        disabled && "group-hover:before:border-n-4/50"
      )}
    >
      {content}
    </span>
  </label>
);

export default Radio;

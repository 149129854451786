import axios from "axios";

// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_BASE_URL = "https://dev.reelaited.com";
// export const API_BASE_URL = "https://aece-219-91-178-234.ngrok-free.app";

export const API_HEADERS = {
  "Content-Type": "application/json",
  // "ngrok-skip-browser-warning": true,
};

// Create a function to get the token from local storage
const getToken = () => {
  try {
    return localStorage.getItem("token");
  } catch (error) {
    return "";
  }
};

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    ...API_HEADERS,
    Authorization: `Token ${getToken()}`, // Get the token dynamically
  },
});

// Intercept requests to dynamically set the token before sending
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

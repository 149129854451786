import { useState } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { twMerge } from "tailwind-merge";
import VideoMakerIcon from "assets/svg/videoMake.svg";
import LibraryIcon from "assets/svg/libraryIcon.svg";

import Navigation from "./Navigation";
import Profile from "./Profile";

import Modal from "components/Modal";
import Icon from "components/Icon";
import Logo from "components/Logo";
import Settings from "components/Settings";
import { ROUTES } from "routes/RouterConfig";

export const settings = [
  {
    id: "edit-profile",
    title: "Edit profile",
    icon: "profile",
  },
  {
    id: "password",
    title: "Password",
    icon: "lock-1",
  },
  {
    id: "voice",
    title: "Voice",
    icon: "volume",
  },
  {
    id: "pre-prompt",
    title: "Pre-prompt",
    icon: "box",
  },
  {
    id: "logout",
    title: "Logout",
    icon: "logout",
  },
];

const LeftSidebar = ({
  value,
  setValue,
  smallSidebar,
  visibleRightSidebar,
}) => {
  const [visibleSettings, setVisibleSettings] = useState(false);

  const navigation = [
    {
      title: "Video Maker",
      svgSrc: VideoMakerIcon,
      color: "fill-accent-1",
      url: ROUTES.VIDEO_MAKER,
    },
    {
      title: "Gallery",
      icon: "gallery",
      color: "fill-accent-5",
      url: ROUTES.GALLERY,
    },
    {
      title: "Library",
      svgSrc: LibraryIcon,
      color: "fill-accent-2",
      url: ROUTES.LIBRARY,
    },
    {
      title: "Settings",
      icon: "settings",
      color: "fill-accent-3",
      onClick: () => setVisibleSettings(true),
    },
  ];

  const handleClick = () => {
    setValue(!value);
    smallSidebar && value ? disablePageScroll() : enablePageScroll();
  };

  return (
    <>
      <div
        className={twMerge(
          `fixed z-20 top-0 left-0 bottom-0 flex flex-col pt-30 px-4 bg-n-7 invisible md:visible opacity-0 md:opacity-100 transition-opacity ${
            value ? "w-24 pb-38  px-0" : "w-72 pb-58"
          } ${visibleRightSidebar && "visible opacity-100"}`
        )}
      >
        <div
          className={`absolute top-0 right-0 left-0 flex items-center h-30 pl-7 pr-6 ${
            value ? "justify-center md:px-4" : "justify-between"
          }`}
        >
          {!value && <Logo className="w-[70%]" />}
          <button className="group tap-highlight-color" onClick={handleClick}>
            <Icon
              className="transition-colors fill-n-4 group-hover:fill-n-3"
              name={value ? "toggle-on" : "toggle-off"}
            />
          </button>
        </div>
        <div className="overflow-y-auto grow scroll-smooth scrollbar-none">
          <Navigation visible={value} items={navigation} />
          <div
            className={`my-4 h-0.25 bg-n-6 ${
              value ? "-mx-4 md:mx-0" : "-mx-2 md:mx-0"
            }`}
          ></div>
        </div>
        <div className="absolute left-0 bottom-0 right-0 pb-6 px-4 bg-n-7 before:absolute before:left-0 before:right-0 before:bottom-full before:h-10 before:bg-gradient-to-t before:from-[#131617] before:to-[rgba(19,22,23,0)] before:pointer-events-none md:px-3s">
          <Profile visible={value} setVisibleSettings={setVisibleSettings} />
        </div>
      </div>
      <Modal
        className="md:!p-0"
        classWrap="max-w-[46rem] md:rounded-lg p-4 sm:py-4 py-8"
        classButtonClose="block absolute md:top-5 md:right-5 top-3 right-3 fill-n-1 "
        classOverlay="bg-n-2"
        visible={visibleSettings}
        onClose={() => setVisibleSettings(false)}
      >
        <Settings items={settings} />
      </Modal>
    </>
  );
};

export default LeftSidebar;

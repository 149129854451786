import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useDispatch, useSelector } from "react-redux";

import Icon from "components/Icon";
import Radio from "components/Radio";
import AudioPlayer from "components/AudioPlayer";
import { IMAGES } from "utils/constants";
import {
  getVoiceDataAction,
  selectVoiceData,
  setPreferVoiceAction,
} from "store/UserSlice";

const SCROLL_CONFIG = {
  behavior: "smooth",
  block: "center",
  inline: "center",
};

export const Voice = ({ preferVoiceId, setSelectedVoice }) => {
  const { voices = [] } = useSelector(selectVoiceData);

  const [audioPlayerData, setAudioPlayerData] = useState({
    audioFile: "",
    name: "",
    id: "",
  });

  const dispatch = useDispatch();
  const selectedVoiceRef = useRef();

  useEffect(() => {
    if (voices.length === 0) dispatch(getVoiceDataAction());
  }, [dispatch, voices]);

  // scroll to selected voice
  useEffect(() => {
    if (selectedVoiceRef.current) {
      selectedVoiceRef.current.scrollIntoView(SCROLL_CONFIG);
    }
  }, [preferVoiceId, voices]);

  // Function to handle loading and playing audio file
  const handleAudioPlay = ({ audioFile, name, id }) => {
    setAudioPlayerData({ audioFile, name, id });
  };

  // Function to handle selecting voice and setting it as preferred voice
  const handleVoiceSelect = (id) => {
    if (setSelectedVoice && typeof setSelectedVoice === "function") {
      setSelectedVoice(id);
    } else {
      dispatch(setPreferVoiceAction({ voice_id: id }));
    }
  };

  return (
    <div className=" ss:px-4">
      <div className="mb-8 h4 md:mb-6">Select Voice</div>
      <div className="max-h-[65vh] overflow-y-scroll">
        {voices?.map((voice) => {
          const { voice_id, name, voice_uri } = voice || {};
          const isPreferredVoiceSelected = preferVoiceId === voice_id;
          const isAudioPlaying = audioPlayerData.id === voice_id;

          return (
            <div
              ref={isPreferredVoiceSelected ? selectedVoiceRef : null}
              key={voice_id}
              className="flex flex-col items-center gap-2 px-4 mb-6 ss:flex-row"
            >
              <Radio
                value={voice_id}
                name="voice"
                checked={isPreferredVoiceSelected}
                onChange={() => handleVoiceSelect(voice_id)}
              />
              {isAudioPlaying ? (
                <AudioPlayer
                  audioFile={audioPlayerData.audioFile}
                  name={audioPlayerData.name}
                  isSelected={isPreferredVoiceSelected}
                  autoPlay={true}
                />
              ) : (
                <div
                  className={twMerge(
                    "w-full h-16 px-3.5 border-2 rounded-xl base2 outline-none transition-colors placeholder:text-n-4/50 bg-n-6 focus:bg-transparent flex items-center justify-between border-n-5 text-n-1 hover:bg-n-3/50 group gap-4 overflow-hidden hover:text-black",
                    isPreferredVoiceSelected && "bg-n-2 text-black"
                  )}
                >
                  <div className="flex items-center gap-[2px] md:gap-4">
                    <button
                      onClick={() =>
                        handleAudioPlay({
                          name,
                          id: voice_id,
                          audioFile: voice_uri,
                        })
                      }
                    >
                      <Icon
                        className={twMerge(
                          "md:w-8 md:h-8 w-7 h-7 p-2 mr-3 transition-colors rounded-full fill-n-7 bg-n-1 group-hover:bg-primary-2 group-hover:fill-n-1",
                          isPreferredVoiceSelected
                            ? "fill-n-1 bg-primary-2"
                            : ""
                        )}
                        name={
                          audioPlayerData.id === voice_id ? "pause" : "play"
                        }
                      />
                    </button>
                    <p>{name}</p>
                  </div>
                  <img
                    src={IMAGES.DEFAULT_MUSIC_WAVE}
                    alt="wave"
                    className="w-[60%] object-contain"
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

import { twMerge } from "tailwind-merge";
import { NavLink } from "react-router-dom";
import Icon from "components/Icon";

const Navigation = ({ visible, items }) => {
  return (
    <div className={`${visible && "px-4"}`}>
      {items.map((item, index) =>
        item.url ? (
          <NavLink
            className={({ isActive }) =>
              twMerge(
                `flex items-center h-12 rounded-md base2  ${
                  isActive &&
                  "text-n-1 bg-gradient-to-b rounded-lg from-[#323337] to-[#464F6F]/50 "
                } ${visible ? "px-3 justify-center" : "px-5"}`
              )
            }
            to={item.url}
            key={index}
          >
            {({ isActive }) => (
              <>
                {!item.svgSrc ? (
                  <Icon
                    // className={twMerge(isActive ? item.color : item.color)}
                    className={item.color}
                    name={item.icon}
                    viewBox={item.iconViewBox}
                  />
                ) : (
                  <img
                    className={twMerge(`w-6 h-6`, item.color)}
                    src={item.svgSrc}
                    alt={item.title}
                  />
                )}
                {!visible && (
                  <div
                    className={twMerge(
                      `ml-5 text-xl`,
                      isActive ? "text-n-1" : "text-n-1"
                    )}
                  >
                    {item.title}
                  </div>
                )}
              </>
            )}
          </NavLink>
        ) : (
          <button
            className={`flex items-center w-full h-12 base2 font-semibold text-n-3/75 rounded-lg transition-colors hover:text-n-1 ${
              visible ? "px-3 justify-center" : "px-5"
            }`}
            key={index}
            onClick={item.onClick}
          >
            <Icon className={item.color} name={item.icon} />
            {!visible && (
              <div className="ml-5 text-xl text-n-3">{item.title}</div>
            )}
          </button>
        )
      )}
    </div>
  );
};

export default Navigation;

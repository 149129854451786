import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import axiosInstance, { API_BASE_URL } from "utils/api";
import { HTTP_REQUEST_METHODS } from "utils/constants";
import { errorToast, successToast } from "utils/helper";

const initialState = {
  userData: null,
  voiceData: [],
  preferVoiceId: null,
  fcmToken: null,
};

const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { voice, ...allData } = action.payload?.message || {};
      state.userData = allData;
      state.preferVoiceId = voice?.voice_id;
    },
    setVoiceData: (state, action) => {
      state.voiceData = action.payload;
    },
    setPreferVoiceId: (state, action) => {
      state.preferVoiceId = action.payload;
    },
    resetUserSliceState: (state) => {
      Object.assign(state, initialState);
    },
    setFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
  },
});

export const getUserDataAction =
  ({ setLoader = () => {} }) =>
  async (dispatch) => {
    try {
      setLoader(true);
      const { data } = await axiosInstance({
        method: HTTP_REQUEST_METHODS.GET,
        url: "/accounts/edituser/",
      });
      dispatch(setUserData(data));
    } catch (error) {
      errorToast({ dispatch, error });
    } finally {
      setLoader(false);
    }
  };

export const updateUserProfileAction =
  ({ payload, setButtonLoader = () => {}, showToast = true }) =>
  async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      setButtonLoader(true);
      const localHeader = {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      };

      const formData = new FormData();
      for (let key in payload) {
        formData.append(key, payload[key]);
      }
      const { data } = await axios({
        method: HTTP_REQUEST_METHODS.PATCH,
        headers: localHeader,
        url: `${API_BASE_URL}/accounts/edituser/`,
        data: formData,
      });

      showToast && successToast(data, "Profile updated successfully.");
      dispatch(getUserDataAction({ setLoader: setButtonLoader }));
    } catch (error) {
      // errorToast({ dispatch, error });
      const errorData = error?.response?.data?.error;
      if (typeof errorData === "object") {
        errorToast({ message: Object.values(errorData)[0] });
      } else {
        errorToast({ error });
      }
    } finally {
      setButtonLoader(false);
    }
  };

export const getVoiceDataAction = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance({
      method: HTTP_REQUEST_METHODS.GET,
      url: "/voice/list_voice/",
    });

    dispatch(setVoiceData(data));
  } catch (error) {
    errorToast({ dispatch, error });
  }
};

export const setPreferVoiceAction =
  ({ voice_id, setButtonLoader = () => {} }) =>
  async (dispatch) => {
    try {
      setButtonLoader(true);
      const { data } = await axiosInstance({
        method: HTTP_REQUEST_METHODS.POST,
        url: "/voice/set_voice/",
        data: { voice_id },
      });
      successToast(data, "Voice preference updated successfully.");
      dispatch(setPreferVoiceId(voice_id));
    } catch (error) {
      errorToast({ dispatch, error });
    } finally {
      setButtonLoader(false);
    }
  };

export const addFcmTokenAction =
  ({ fcmToken }) =>
  async (dispatch) => {
    const token = localStorage.getItem("token");
    const payload = {
      user_device_token: fcmToken,
      user_token: token,
    };
    try {
      const { data } = await axiosInstance({
        method: HTTP_REQUEST_METHODS.POST,
        url: "/accounts/add-device-token/",
        data: payload,
      });
      dispatch(setFcmToken(fcmToken));
    } catch (error) {
      errorToast({ dispatch, error });
    }
  };

export const selectUserData = (state) => state.User.userData;
export const selectVoiceData = (state) => state.User.voiceData;
export const selectPreferVoiceId = (state) => state.User.preferVoiceId;
export const selectFcmToken = (state) => state.User.fcmToken;

export const {
  setUserData,
  setVoiceData,
  setPreferVoiceId,
  resetUserSliceState,
  setFcmToken,
} = UserSlice.actions;

export default UserSlice.reducer;

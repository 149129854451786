import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "utils/baseQueryWithReauth";

import { errorToast } from "utils/helper";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReAuth(),

  endpoints: (builder) => ({
    getAvailableVoices: builder.query({
      query: () => "/voice/list_voice/",
      transformResponse: (response) => response?.voices || [],
      transformErrorResponse: (error) => {
        console.log("error", error);
        errorToast({ error });
        return error;
      },
    }),
  }),
});

export const { useGetAvailableVoicesQuery } = userApi;

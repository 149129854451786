import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Icon from "components/Icon";
import { getUserDataAction, selectUserData } from "store/UserSlice";

const Profile = ({ visible, setVisibleSettings }) => {
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  const { first_name, last_name, profile_image, email } = userData || {};

  useEffect(() => {
    // if user data is not available then fetch user data
    if (!userData) dispatch(getUserDataAction({}));
  }, [dispatch, userData]);

  return (
    <div
      className={`${
        visible ? "mb-6" : "mb-3 shadow-[0_1.25rem_1.5rem_0_rgba(0,0,0,0.5)]"
      }`}
    >
      <div className={`${!visible && "p-2.5 bg-n-6 rounded-xl"}`}>
        <div
          className={`flex items-center ${
            visible ? "justify-center" : "px-2.5 py-2.5 pb-4.5"
          }`}
        >
          <button
            onClick={() => setVisibleSettings(true)}
            className="relative flex items-center justify-center w-10 h-10 border rounded-full"
          >
            {profile_image ? (
              <img
                className="object-cover w-10 h-10 rounded-full"
                src={profile_image}
                alt="Profile"
              />
            ) : (
              <Icon className="fill-n-1" name="profile" />
            )}
          </button>
          {!visible && (
            <>
              <div className="ml-4 mr-4">
                <div className="font-semibold base2 text-n-1">
                  {first_name} {last_name}
                </div>
                <div className="font-semibold truncate caption1 text-n-3/50 w-34">
                  {email}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import axiosInstance, { API_BASE_URL, API_HEADERS } from "utils/api";
import { errorToast, successToast } from "utils/helper";
import { HTTP_REQUEST_METHODS } from "utils/constants";
import { resetUserSliceState } from "store/UserSlice";

const initialState = {
  isLoggedIn: false,
  token: null,
  emailId: null,
  firstName: null,
  lastName: null,
};

export const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    login: (state, action) => {
      const { email, token } = action.payload;
      state.isLoggedIn = true;
      state.token = token;
      state.emailId = email;
      localStorage.setItem("token", token);
    },
    loggedOut: (state) => {
      // clear the state to initial state
      Object.assign(state, initialState);
      // remove the token from local storage
      localStorage.removeItem("token");
    },
  },
});

export const loginAction =
  ({ payload, setIsLoading, onSuccess }) =>
  async (dispatch) => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        method: HTTP_REQUEST_METHODS.POST,
        url: `${API_BASE_URL}/accounts/signin/`,
        data: payload,
        headers: API_HEADERS,
      });
      dispatch(login(data));
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Token ${data.token}`;
      onSuccess();
      successToast(data, "Logged in successfully");

      return;
    } catch (error) {
      errorToast({ error });
    } finally {
      setIsLoading(false);
    }
  };

export const googleLoginAction =
  ({ data, setIsLoading, onSuccess }) =>
  async (dispatch) => {
    setIsLoading(true);
    try {
      const response = await axios({
        method: HTTP_REQUEST_METHODS.POST,
        url: `${API_BASE_URL}/accounts/googlelogin/`,
        data: data,
        headers: API_HEADERS,
      });
      dispatch(login(response.data));
      onSuccess();
      successToast(response, "Logged in successfully");
    } catch (error) {
      errorToast({ error });
    } finally {
      setIsLoading(false);
    }
  };

export const registerAction =
  ({ payload, setIsLoading, onSuccess }) =>
  async () => {
    setIsLoading(true);
    try {
      await axios({
        method: HTTP_REQUEST_METHODS.POST,
        url: `${API_BASE_URL}/accounts/signup/`,
        data: payload,
        headers: API_HEADERS,
      });
      successToast("", "Registered successfully");
      onSuccess();
    } catch (error) {
      const errorData = error?.response?.data?.error;
      if (typeof errorData === "object") {
        errorToast({ message: Object.values(errorData)[0] });
      } else {
        errorToast({ error });
      }
    } finally {
      setIsLoading(false);
    }
  };

export const forgotPasswordAction =
  ({ payload, setIsLoading, setResetPasswordForm }) =>
  async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        method: HTTP_REQUEST_METHODS.POST,
        url: `${API_BASE_URL}/accounts/forgetpassword/`,
        data: payload,
        headers: API_HEADERS,
      });
      successToast(data, "OTP sent successfully to email");
      setResetPasswordForm({
        isResetPasswordFormVisible: true,
        email: payload.email,
      });
    } catch (error) {
      errorToast({ error });
    } finally {
    }
  };

export const resetPasswordAction =
  ({ payload, setIsLoading, onSuccess }) =>
  async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        method: HTTP_REQUEST_METHODS.POST,
        url: `${API_BASE_URL}/accounts/resetpassword/`,
        data: payload,
        headers: API_HEADERS,
      });
      successToast(data, "Password reset successfully");
      onSuccess();
    } catch (error) {
      errorToast({ error });
    } finally {
      setIsLoading(false);
    }
  };

export const ChangePasswordAction =
  ({ payload, setButtonLoader, token, reset }) =>
  async (dispatch) => {
    setButtonLoader(true);
    // const localHeader = { ...API_HEADERS, Authorization: `Token ${token}` };
    try {
      // const response = await axios({
      const { data } = await axiosInstance({
        method: HTTP_REQUEST_METHODS.PUT,
        url: `${API_BASE_URL}/accounts/changepassword/`,
        data: payload,
      });
      successToast(data, "Password changed successfully");
      reset();
    } catch (error) {
      errorToast({ dispatch, error });
    } finally {
      setButtonLoader(false);
    }
  };

export const logoutAction = (fcmToken) => async (dispatch) => {
  try {
    const { data } = await axiosInstance({
      method: HTTP_REQUEST_METHODS.POST,
      url: `${API_BASE_URL}/accounts/logout/`,
      data: { device_token: fcmToken },
    });
    successToast(data, "Logged out successfully");
    dispatch(loggedOut());
  } catch (error) {
    dispatch(loggedOut());
  } finally {
    dispatch(resetUserSliceState());
  }
};

export const selectToken = (state) => state.Auth.token;
export const selectIsLoggedIn = (state) => state.Auth.isLoggedIn;
export const selectUserEmailId = (state) => state.Auth.emailId;
export const selectUserFirstName = (state) => state.Auth.firstName;
export const selectUserLastName = (state) => state.Auth.lastName;
export const selectUserFullName = (state) =>
  `${state.Auth.firstName} ${state.Auth.lastName}`;

export const { login, loggedOut } = AuthSlice.actions;
export default AuthSlice.reducer;

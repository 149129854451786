import React from "react";
import { twMerge } from "tailwind-merge";

import Icon from "components/Icon";

const Button = ({
  title,
  loader = false,
  icon = "",
  rightIcon = "",
  iconClassName = "",
  iconViewBox = "0 0 24 24",
  rightIconClassName = "",
  className = "",
  classLabel = "",
  type = "button",
  ...otherProps
}) => {
  return (
    <button type={type} {...otherProps} className={twMerge("btn ", className)}>
      {!loader ? (
        <>
          {icon && (
            <Icon
              className={twMerge(
                "transition-colors fill-n-1 group-hover:fill-n-5",
                iconClassName
              )}
              name={icon}
              viewBox={iconViewBox}
            />
          )}
          {title && <p className={classLabel}>{title}</p>}
          {rightIcon && (
            <Icon
              className={twMerge(
                "transition-colors fill-n-4 group-hover:fill-n-5",
                rightIconClassName
              )}
              name={rightIcon}
              viewBox={iconViewBox}
            />
          )}
        </>
      ) : (
        <div className="w-8 h-8 border-t-2 border-b-2 border-white rounded-full animate-spin" />
      )}
    </button>
  );
};

export default Button;

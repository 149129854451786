import { createSlice } from "@reduxjs/toolkit";

import axiosInstance from "utils/api";
import { HTTP_REQUEST_METHODS } from "utils/constants";
import { errorToast } from "utils/helper";

const initialState = {
  scriptData: [],
  editedPartIds: [],
  videoMakerResultStateData: {},
};

const VideoMakerSlice = createSlice({
  name: "VideoMaker",
  initialState,
  reducers: {
    setScriptDataReducer: (state, action) => {
      state.scriptData = action.payload;
    },
    updateScriptDataReducer: (state, action) => {
      state.scriptData = {
        ...state.scriptData,
        ...action.payload,
      };
    },
    clearScriptData: (state) => {
      state.scriptData = [];
    },
    setEditedPartIds: (state, action) => {
      const { id } = action.payload;
      if (!state.editedPartIds.includes(id)) {
        state.editedPartIds.push(id);
      }
    },
    removeEditedPartId: (state, action) => {
      state.editedPartIds = state.editedPartIds.filter(
        (id) => id !== action.payload
      );
    },
    setVideoMakerResultStateData: (state, action) => {
      state.videoMakerResultStateData = action.payload;
    },
    clearVideoMakerState: (state) => {
      state.videoMakerResultStateData = {};
    },
  },
});

export const generateScriptAction =
  ({ payload, setButtonLoader, onSuccess }) =>
  async (dispatch) => {
    try {
      setButtonLoader(true);
      await axiosInstance({
        method: HTTP_REQUEST_METHODS.POST,
        url: "/videogenerator/generateScript/",
        data: payload,
      });
      onSuccess();
    } catch (error) {
      errorToast({ dispatch, error });
      setButtonLoader(false);
    }
  };

export const selectScriptData = (state) => state.VideoMaker.scriptData;
export const selectEditedPartIds = (state) => state.VideoMaker.editedPartIds;
export const selectVideoMakerResultStateData = (state) =>
  state.VideoMaker.videoMakerResultStateData;

export const {
  setScriptDataReducer,
  removeEditedPartId,
  setEditedPartIds,
  setVideoMakerResultStateData,
  clearVideoMakerState,
  clearScriptData,
  updateScriptDataReducer,
} = VideoMakerSlice.actions;

export default VideoMakerSlice.reducer;

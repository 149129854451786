import { createSlice } from "@reduxjs/toolkit";

import axiosInstance, { API_BASE_URL } from "utils/api";
import { HTTP_REQUEST_METHODS } from "utils/constants";
import { errorToast, successToast } from "utils/helper";

const initialState = {
  libraryData: [],
};

const LibrarySlice = createSlice({
  name: "Library",
  initialState,
  reducers: {
    setLibraryData: (state, action) => {
      state.libraryData = action.payload;
    },
  },
});

export const getLibraryData =
  ({ setLoader = () => {} }) =>
  async (dispatch) => {
    try {
      setLoader(true);
      const { data } = await axiosInstance({
        method: HTTP_REQUEST_METHODS.GET,
        url: `${API_BASE_URL}/videogenerator/mylibrary/`,
      });

      dispatch(setLibraryData(data));
    } catch (error) {
      errorToast({ dispatch, error });
    } finally {
      setLoader(false);
    }
  };

export const deleteConceptAction =
  ({ ids, setLoader = () => {}, onSuccess }) =>
  async (dispatch) => {
    try {
      setLoader(true);
      const { data } = await axiosInstance({
        method: HTTP_REQUEST_METHODS.DELETE,
        url: `/videogenerator/deletevideo/`,
        data: { concept_ids: ids },
      });

      successToast(data, "Deleted successfully!");
    } catch (error) {
      errorToast({ dispatch, error });
    } finally {
      setLoader(false);
    }
  };

export const deleteVideoSrcAction =
  ({ data, setLoader = () => {}, onSuccess }) =>
  async (dispatch) => {
    try {
      setLoader(true);
      const response = await axiosInstance({
        method: HTTP_REQUEST_METHODS.DELETE,
        url: `/videogenerator/deletesinglevideo/`,
        data,
      });
      onSuccess();
      successToast(response, "Deleted successfully!");
    } catch (error) {
      errorToast({ dispatch, error });
    } finally {
      setLoader(false);
    }
  };

export const selectLibraryData = (state) => state.Library.libraryData?.results;

export const { setLibraryData } = LibrarySlice.actions;

export default LibrarySlice.reducer;

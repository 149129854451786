import React, { useEffect } from "react";
import { useRouteError } from "react-router-dom";

import { IMAGES } from "utils/constants";

// import Layout from "components/Layout";

const ErrorFallback = () => {
  const error = useRouteError();

  useEffect(() => {
    // logError(error);
  }, [error]);

  return (
    // <Layout>

    <div className="flex flex-col items-center w-full h-screen">
      <img
        src={IMAGES.ERROR_FALLBACK}
        alt="error"
        className="object-contain w-full h-[80vh]"
      />
    </div>
    // </Layout>
  );
};

export default ErrorFallback;

import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";

import Menu from "./Menu";
import EditProfile from "./EditProfile";
import Password from "./Password";
import Select from "components/Select";
import PrePrompt from "components/Settings/PrePrompt";
import { Voice } from "./Voice";
import { logoutAction } from "store/AuthSlice";
import { selectFcmToken, selectPreferVoiceId } from "store/UserSlice";

const Settings = ({ items, activeItem }) => {
  const fcmToken = useSelector(selectFcmToken);
  const preferVoiceId = useSelector(selectPreferVoiceId);
  const [active, setActive] = useState(items[activeItem || 0]);

  const dispatch = useDispatch();

  const onActiveChange = (value) => {
    if (value.id === "logout") {
      dispatch(logoutAction(fcmToken));
      return;
    }
    setActive(value);
  };

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return (
    <div className="p-5 shadow-2xl lg:px-8 md:pt-16 md:px-5 md:pb-8">
      <div className="block md:flex">
        {isMobile ? (
          <Select
            className="mb-6"
            classButton="bg-transparent"
            classArrow="fill-n-4"
            items={items}
            value={active}
            onChange={onActiveChange}
          />
        ) : (
          <div className="shrink-0 w-[13.25rem]">
            <Menu value={active} setValue={onActiveChange} buttons={items} />
          </div>
        )}
        <div className="pl-0 md:pl-12 grow">
          {active.id === "edit-profile" && <EditProfile />}
          {active.id === "password" && <Password />}
          {active.id === "voice" && <Voice preferVoiceId={preferVoiceId} />}
          {active.id === "pre-prompt" && <PrePrompt />}
        </div>
      </div>
    </div>
  );
};

export default Settings;

import { createApi } from "@reduxjs/toolkit/query/react";

import { MISC_END_POINTS } from "api/endPoints";
import { HTTP_REQUEST_METHODS } from "utils/constants";
import { baseQueryWithReAuth } from "utils/baseQueryWithReauth";

export const miscApi = createApi({
  reducerPath: "miscApi",
  baseQuery: baseQueryWithReAuth(),
  endpoints: (builder) => ({
    saveEditedImage: builder.mutation({
      query: (payload) => ({
        url: MISC_END_POINTS.SAVE_EDITED_IMAGE,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
    }),
    getMoviePyFontFamily: builder.query({
      query: () => ({
        url: MISC_END_POINTS.GET_MOVIE_PY_FONT_FAMILY,
        method: HTTP_REQUEST_METHODS.GET,
      }),
    }),
    getMoviePyFontColor: builder.query({
      query: () => ({
        url: MISC_END_POINTS.GET_MOVIE_PY_FONT_COLOR,
        method: HTTP_REQUEST_METHODS.GET,
      }),
    }),
    getMoviePyFontSize: builder.query({
      query: () => ({
        url: MISC_END_POINTS.GET_MOVIE_PY_FONT_SIZE,
        method: HTTP_REQUEST_METHODS.GET,
      }),
    }),
  }),
});

export const {
  useSaveEditedImageMutation,
  useGetMoviePyFontColorQuery,
  useGetMoviePyFontFamilyQuery,
  useGetMoviePyFontSizeQuery,
} = miscApi;

import { createApi } from "@reduxjs/toolkit/query/react";
import { VIDEO_LIBRARY_END_POINTS } from "api/endPoints";
import { baseQueryWithReAuth } from "utils/baseQueryWithReauth";
import { HTTP_REQUEST_METHODS } from "utils/constants";

export const videoLibraryApi = createApi({
  reducerPath: "videoLibraryApi",
  baseQuery: baseQueryWithReAuth(),
  endpoints: (builder) => ({
    publishVideo: builder.mutation({
      query: (data) => ({
        url: VIDEO_LIBRARY_END_POINTS.PUBLISH_VIDEO,
        method: HTTP_REQUEST_METHODS.POST,
        body: data,
      }),
    }),
    checkPublishStatus: builder.mutation({
      query: (data) => ({
        url: VIDEO_LIBRARY_END_POINTS.CHECK_PUBLISH_STATUS,
        method: HTTP_REQUEST_METHODS.POST,
        body: data,
      }),
    }),
  }),
});

export const { usePublishVideoMutation, useCheckPublishStatusMutation } =
  videoLibraryApi;

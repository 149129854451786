import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/Button";
import Field from "components/Field";
import { selectUserData, updateUserProfileAction } from "store/UserSlice";
import { selectToken } from "store/AuthSlice";

const LOGIN_FORM_SCHEMA = object().shape({
  videoPrompt: string().required("Video prompt is required"),
  imagePrompt: string().required("Image prompt is required"),
});

const DEFAULT_VALUES = {
  videoPrompt: "",
  imagePrompt: "",
};

const PrePrompt = () => {
  const userData = useSelector(selectUserData);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(LOGIN_FORM_SCHEMA),
  });

  // set default values in form fields
  useEffect(() => {
    if (!userData) return;
    setValue("videoPrompt", userData.video_prompt);
    setValue("imagePrompt", userData.prompt);
  }, [userData, setValue]);

  const onSubmit = (data) => {
    const payload = {};

    // prepare payload to send to server
    if (dirtyFields.videoPrompt) payload.video_prompt = data.videoPrompt;
    if (dirtyFields.imagePrompt) payload.prompt = data.imagePrompt;

    // if no field is changed then return
    if (Object.keys(payload).length === 0) return;

    dispatch(
      updateUserProfileAction({
        token,
        payload,
        setButtonLoader: setIsLoading,
      })
    );
  };

  return (
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <Field
        textarea
        type="text"
        minRows={2}
        label="Image prompt"
        className="mb-4"
        placeholder="Image prompt"
        error={errors.imagePrompt?.message}
        {...register("imagePrompt")}
      />
      <Field
        textarea
        type="text"
        minRows={2}
        className="mb-2"
        label="Video prompt"
        placeholder="Video prompt"
        error={errors.videoPrompt?.message}
        {...register("videoPrompt")}
      />

      <Button
        title="Save"
        type="submit"
        loader={isLoading}
        className="w-full my-5 btn-blue btn-large"
      />
    </form>
  );
};

export default PrePrompt;

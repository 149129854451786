import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import Icon from "components/Icon";
import Field from "components/Field";
import Button from "components/Button";
import {
  getUserDataAction,
  selectUserData,
  updateUserProfileAction,
} from "store/UserSlice";
import { selectToken } from "store/AuthSlice";

const FORM_SCHEMA = object().shape({
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
});

const EditProfile = () => {
  const token = useSelector(selectToken);
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  const [objectURL, setObjectURL] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    // if user data is not available then fetch user data
    if (!userData) dispatch(getUserDataAction({}));
  }, [dispatch, userData]);

  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      profileImage: "",
    },
    resolver: yupResolver(FORM_SCHEMA),
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (userData) {
      const { first_name, last_name, profile_image } = userData;
      setValue("firstName", first_name);
      setValue("lastName", last_name);
      setValue("profileImage", profile_image);
      setObjectURL(profile_image);
    }
  }, [userData, setValue]);

  const onSubmit = (data) => {
    // only send the fields which are dirty and not empty to the server

    const payload = {
      first_name: data.firstName,
      last_name: data.lastName,
      profile_image: data.profileImage || "None",
    };

    // if profile image is url or not changed then don't send it in payload
    if (data.profileImage === userData.profile_image) {
      delete payload.profile_image;
    }

    dispatch(updateUserProfileAction({ payload, setButtonLoader, token }));
  };

  const handleUploadImageBtn = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      setObjectURL(URL.createObjectURL(file));
      form.setValue("profileImage", file);
    }
  };

  return (
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-8 h4 md:mb-6">Edit profile</div>
      <div className="mb-3 font-semibold base2 text-n-1">Avatar</div>
      <div className="flex items-center mb-6">
        <div className="relative flex items-center justify-center mr-4 overflow-hidden rounded-full shrink-0 w-28 h-28 bg-n-6">
          {objectURL !== null ? (
            <img
              className="object-cover rounded-full"
              src={objectURL}
              alt="Avatar"
            />
          ) : (
            <Icon className="w-8 h-8 fill-n-1" name="profile" />
          )}
        </div>
        <div className="grow">
          <div className="relative inline-flex mb-4">
            <input
              className="absolute inset-0 opacity-0 cursor-pointer peer"
              type="file"
              accept="image/*"
              onChange={handleUploadImageBtn}
            />
            <button type="button" className="btn-stroke-dark peer-hover:bg-n-5">
              Upload new image
            </button>
          </div>
        </div>
      </div>
      <label className="block mb-3 font-semibold base2 text-n-1" htmlFor="name">
        First Name
      </label>
      <Field
        className="mb-4"
        placeholder="First Name"
        icon="profile"
        autoComplete="off"
        error={errors.firstName?.message}
        {...register("firstName")}
      />
      <label className="block mb-3 font-semibold base2 text-n-1" htmlFor="name">
        Last Name
      </label>
      <Field
        className="mb-4"
        placeholder="Last Name"
        icon="profile"
        autoComplete="off"
        error={errors.lastName?.message}
        {...register("lastName")}
      />

      <Button
        type="submit"
        title="Save changes"
        loader={buttonLoader}
        className="w-full mt-8 btn-blue"
      />
    </form>
  );
};

export default EditProfile;

import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import "./index.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-photo-view/dist/react-photo-view.css";
import reportWebVitals from "./reportWebVitals";
import store from "store/store";

const toastOptions = {
  duration: 3000,
  success: {
    style: {
      color: "#009150",
    },
    iconTheme: {
      primary: "#009150",
    },
  },
  error: {
    style: {
      color: "#ff0033",
    },
    iconTheme: {
      primary: "#ff0033",
    },
  },
  loading: {
    style: {
      color: "blue",
    },
    iconTheme: {
      primary: "blue",
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
const persistor = persistStore(store);

// Disable console logs in production mode
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
}

root.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <Toaster
          containerStyle={{ bottom: 40, left: 20, right: 20 }}
          position="bottom-center"
          gutter={10}
          toastOptions={toastOptions}
        />
      </PersistGate>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editedVideoData: {
    scriptId: "",
    logoUrl: "",
    introUrl: "",
    outroUrl: "",
    logoPosition: "",
    introDuration: "",
    outroDuration: "",
    backgroundMusicUrl: "",
  },
  maxEndTime: "0000",
};

const EditVideoSlice = createSlice({
  name: "editVideo",
  initialState,
  reducers: {
    setEditedVideoData: (state, action) => {
      state.editedVideoData = action.payload;
    },
    updateEditedVideoData: (state, action) => {
      state.editedVideoData = {
        ...state.editedVideoData,
        ...action.payload,
      };
    },
    setMaxEndTime: (state, action) => {
      state.maxEndTime = action.payload;
    },
    clearEditedVideoData: (state) => {
      state.editedVideoData = initialState.editedVideoData;
    },
  },
});

export const selectMaxEndTime = (state) => state.EditVideo.maxEndTime;
export const selectEditedVideoData = (state) => state.EditVideo.editedVideoData;

export const {
  setMaxEndTime,
  setEditedVideoData,
  clearEditedVideoData,
  updateEditedVideoData,
} = EditVideoSlice.actions;

export default EditVideoSlice.reducer;
